<template>
  <div v-if="!loading">
    <CCard class="mb-4">
      <CCardBody>
        <div>
          <h4 class="card-title mb-0">Average Order Report</h4>
          <div class="small text-muted">Filter and analyze order statistics</div>
        </div>

        <!-- Filters -->
        <CCard class="filters my-3" accent-color="warning">
          <CCardBody class="p-2">
            <div class="grid">
              <v-select
                class="v-select-filter"
                v-model="averageReport.filters.year"
                :options="years"
                :searchable="false"
                placeholder="Select year..."
                clearable
              />

              <v-select
                class="v-select-filter"
                v-model="averageReport.filters.orderChannel"
                :options="orderChannels"
                :searchable="false"
                placeholder="Order channel.."
                clearable
              />

              <div class="form-check mt-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="averageReport.filters.multipleOrders"
                  id="multipleOrders"
                >
                <label class="form-check-label" for="multipleOrders">
                  Only multiple orders (per customer)
                </label>
              </div>
            </div>
          </CCardBody>
        </CCard>

        <!-- Stats Cards -->
        <div class="row mb-4 stats-cards">
          <div class="col-sm-6 col-lg-3">
            <CWidgetDropdown
              color="primary"
              :header="`${averageReport.data.totalCustomers}`"
              text="Total Customers"
            />
          </div>
          <div class="col-sm-6 col-lg-3">
            <CWidgetDropdown
              color="info"
              :header="`${averageReport.data.totalOrders}`"
              text="Total Orders"
            />
          </div>
          <div class="col-sm-6 col-lg-3">
            <CWidgetDropdown
              color="warning"
              :header="$options.filters.toCurrency(averageReport.data.averageOrderAmount, 'remove_thousand_separator')"
              text="Average Order Amount"
            />
          </div>
          <div class="col-sm-6 col-lg-3">
            <CWidgetDropdown
              color="success"
              :header="`${averageReport.data.ordersPerCustomer}`"
              text="Orders per Customer"
            />
          </div>
        </div>

        <!-- Order Distribution Table -->
        <div class="row">
          <div class="col-md-6">
            <h5>Order Distribution</h5>
            <CDataTable
              :items="orderDistributionItems"
              :fields="[
                { key: 'orderCount', label: 'Order Count' },
                { key: 'customerCount', label: 'Customer Count' }
              ]"
              striped
              small
            />
          </div>
          <div class="col-md-6">
            <h5>Additional Statistics</h5>
            <CDataTable
              :items="[averageReport.data]"
              :fields="[
                { key: 'minOrderAmount', label: 'Min Order Amount (€)' },
                { key: 'maxOrderAmount', label: 'Max Order Amount (€)' },
                { key: 'uniqueOrderDays', label: 'Unique Order Days' }
              ]"
              striped
              small
            >
              <template #minOrderAmount="{ item }">
                <td>
                  <CLink v-if="item.minOrderAmount && item.minOrderAmount.orderId"
                    :to="{ name: 'Order Details', params: { id: item.minOrderAmount.orderId } }"
                    class="font-weight-bold">
                    {{ item.minOrderAmount.amount | toCurrency('remove_thousand_separator') }}
                  </CLink>
                  <span v-else>-</span>
                </td>
              </template>

              <template #maxOrderAmount="{ item }">
                <td>
                  <CLink v-if="item.maxOrderAmount && item.maxOrderAmount.orderId"
                    :to="{ name: 'Order Details', params: { id: item.maxOrderAmount.orderId } }"
                    class="font-weight-bold">
                    {{ item.maxOrderAmount.amount | toCurrency('remove_thousand_separator') }}
                  </CLink>
                  <span v-else>-</span>
                </td>
              </template>
            </CDataTable>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
  <CSpinner v-else color="primary" />
</template>

<script>

export default {
  name: 'OrderReports',
  data() {
    return {
      loading: true,
      years: [],
      orderChannels: [
        { value: '0', label: 'MS' },
        { value: '1', label: 'SW' },
        { value: '2', label: 'APP' },
      ],

      averageReport: {
        data: {
          totalCustomers: 0,
          totalOrders: 0,
          totalAmount: 0,
          averageOrderAmount: 0,
          ordersPerCustomer: 0,
          minOrderAmount: 0,
          maxOrderAmount: 0,
          uniqueOrderDays: 0,
          orderCountDistribution: {}
        },
        filters: {
          year: null,
          orderChannel: null,
          multipleOrders: false
        }
      },
    }
  },

  computed: {
    orderDistributionItems() {
      return Object.entries(this.averageReport.data.orderCountDistribution || {})
        .map(([orderCount, customerCount]) => ({
          orderCount,
          customerCount
        }))
    }
  },

  watch: {
    'averageReport.filters': {
      deep: true,
      handler() {
        this.getAverageOrderReport()
      }
    },
  },

  created() {
    this.averageReport.filters.year = new Date().getFullYear();
    this.initYear()
  },

  methods: {
    initYear() {
      const currentYear = new Date().getFullYear();
      const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
      this.years = range(currentYear, 2017, -1);
    },

    async getAverageOrderReport() {
      var url = new URL(this.$backend.REPORTS.ORDERS.AVERAGE),
        params = {
          year: this.averageReport.filters.year,
          'order-channel': this.averageReport.filters.orderChannel?.value,
          'multiple-orders': this.averageReport.filters.multipleOrders
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      try {
        const { data } = await this.$axios.get(url)
        this.averageReport.data = data.data
        this.loading = false
      } catch (error) {
        this.$toast.error('Failed to fetch average order report')
      }
    },
  }
}
</script>

<style>
.filters .grid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.stats-cards .card .card-body {
  padding-bottom: 20px !important;
}
</style>
